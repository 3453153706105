import type { StyleRule } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';

export const BaseStyle: StyleRule = ({ theme }) => ({
    paddingTop: pxToRem(40),
    paddingLeft: pxToRem(18),
    paddingRight: pxToRem(18),
    overflow: 'auto',
    [theme.screen.min.tablet]: {
        paddingTop: pxToRem(160),
        paddingLeft: pxToRem(50),
    },
});

export const MainTextStyle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Display.Roman55,
    fontSize: pxToRem(32),
    lineHeight: '130%',
    letterSpacing: pxToRem(1.7),
    [theme.screen.min.tablet]: {
        fontSize: pxToRem(60),
        lineHeight: pxToRem(72),
        letterSpacing: pxToRem(2.7),
    },
});

export const SubTextStyle: StyleRule = ({ theme }) => ({
    ...theme.siteVariables.textStyles.Display.Roman55,
    fontSize: pxToRem(15),
    lineHeight: '120%',
    paddingTop: pxToRem(40),
    paddingBottom: pxToRem(35),
    letterSpacing: pxToRem(0.8),
    paddingRight: pxToRem(10),
    [theme.screen.min.tablet]: {
        paddingTop: pxToRem(44),
        paddingRight: 'unset',
        fontSize: pxToRem(30),
        lineHeight: '100%',
        letterSpacing: pxToRem(1.6),
        paddingBottom: pxToRem(45),
    },
});

export const JoinTextStyle: StyleRule = ({ theme }) => ({
    paddingTop: pxToRem(40),
    ...theme.siteVariables.textStyles.Display.Roman55,
    fontSize: pxToRem(16),
    lineHeight: '150%',
    letterSpacing: pxToRem(0.7),

    [theme.screen.min.tablet]: {
        fontSize: pxToRem(30),
        lineHeight: pxToRem(39),
        letterSpacing: pxToRem(1.7),
    },
});
